import { AxiosResponse, CancelTokenSource } from 'axios';

import { useApi } from '../../config/useApi';
import { SHAPER_SERVICE } from '../auth/const';

import {
  apiService,
  createInstance,
  updateInstanceToken,
} from './Api/Api.service';
import { getAccessToken } from './Cookies.service';
import { ShaperSearchResponse } from './Types/shaper.types';

const shaperInstance = createInstance(SHAPER_SERVICE);

export const useShaperService = () => {
  const { getShaperBaseUrl } = useApi();

  const shaperApi = apiService(`${getShaperBaseUrl()}/api`, shaperInstance);
  const token = getAccessToken();
  updateInstanceToken(shaperInstance, token);

  const requestShaperSearch = async (
    value: string,
    cancelToken: CancelTokenSource,
  ): Promise<AxiosResponse<ShaperSearchResponse>> => {
    return shaperApi.get({
      url: `${getShaperBaseUrl()}/api/`,
      endPoint: `global-search/search/?query=${encodeURIComponent(value)}`,
      cancelToken: cancelToken.token,
    });
  };

  return {
    requestShaperSearch,
  };
};
