import { debounce } from 'lodash';
import { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CenteredLoader, SearchInput } from 'react-ui-kit-exante';

import { useOutsideClick } from '../../hooks/useOutsideClick';
import { useAppDispatch } from '../../store/hooks';
import { MenuItemWithSearch } from '../menu/types';

import { INPUT_SEARCH_DELAY, MIN_SEARCH_LENGTH } from './GlobalSearch.consts';
import {
  CountStyled,
  IconWithTextStyled,
  ItemStyled,
  LoaderStyled,
  ResultPopupStyled,
  SearchContainerStyled,
  TitleStyled,
} from './GlobalSearch.styled';
import { SubItemList } from './components';
import { useOnSearch } from './hooks';
import { clearCrmBadgesState, setCrmQueryState } from './reducer';

export const GlobalSearch = () => {
  const dispatch = useAppDispatch();

  const history = useHistory();

  const [searchData, setSearchData] = useState<MenuItemWithSearch[]>([]);

  const [isFocused, setIsFocused] = useState(false);

  const ref = useRef(null);
  useOutsideClick(ref, () => setIsFocused(false));

  const { isLoading, onSearch, cancelToken } = useOnSearch(setSearchData);

  const onClearSearch = () => {
    if (typeof cancelToken !== typeof undefined) {
      cancelToken.cancel();
    }
    setSearchData([]);
    dispatch(clearCrmBadgesState());
    dispatch(setCrmQueryState(''));
    if (window.CRM_UI?.search) {
      window.CRM_UI.search('');
    }
  };
  const search = debounce((e: string) => {
    if (e.length > MIN_SEARCH_LENGTH) {
      onSearch(e);
    } else {
      onClearSearch();
    }
  }, INPUT_SEARCH_DELAY);

  const onSelectItem = (link?: string) => {
    setIsFocused(false);

    if (link) {
      dispatch(clearCrmBadgesState());
      history.push(link);
    }
  };

  const isShowPopup = (searchData || isLoading) && isFocused;

  return (
    <SearchContainerStyled ref={ref}>
      <SearchInput
        placeholder="Search"
        onChange={search}
        inputProps={{
          onFocus: () => setIsFocused(true),
          fullWidth: true,
        }}
      />

      {isShowPopup && (
        <ResultPopupStyled>
          {isLoading && (
            <LoaderStyled>
              <CenteredLoader isInner size="m" />
            </LoaderStyled>
          )}

          {searchData.map(
            ({
              children: menuChildren,
              defaultLink,
              iconSrc,
              text,
              count,
            }: MenuItemWithSearch) => (
              <ItemStyled key={text}>
                <TitleStyled
                  title={text}
                  onClick={() => onSelectItem(defaultLink)}
                >
                  <IconWithTextStyled>
                    <img alt={text} src={iconSrc?.default} width="24" />
                    {text}
                  </IconWithTextStyled>

                  <CountStyled>{count}</CountStyled>
                </TitleStyled>

                {menuChildren ? (
                  <SubItemList
                    menuChildren={menuChildren}
                    onSelectItem={onSelectItem}
                  />
                ) : null}
              </ItemStyled>
            ),
          )}
        </ResultPopupStyled>
      )}
    </SearchContainerStyled>
  );
};
