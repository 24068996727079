import { ShaperSearchResponse } from '../../services/Types/shaper.types';
import { ParseSearchReturn } from '../types';

export function parseShaperSearch(
  { result: array, tabs }: ShaperSearchResponse,
  dict: Record<string, string>,
) {
  return array.reduce<ParseSearchReturn>(
    (acc, item) => {
      // `tab` field may, for unclear reasons, not contains "s" letter at the end for 'events' and 'mailings'
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore TODO TS incorrect defines type from endsWith, probably it will be fix after update of TS version
      const tabWithS: 'events' | 'archive' | 'mailings' =
        item.tab.endsWith('s') && item.tab !== 'archive'
          ? item.tab
          : `${item.tab}s`;

      acc.searchResult.push({
        link: `/ns${item.link}`,
        path: dict[`/ns/${tabWithS}`],
        text: item.snippet,
      });

      acc.countByPath[dict[`/ns/${tabWithS}`]] = tabs[tabWithS];

      return acc;
    },
    {
      searchResult: [],
      countByPath: {},
    },
  );
}
