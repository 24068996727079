import { CprmSearchResponse } from '../../services/Types/cprm.types';
import { ParseSearchReturn } from '../types';

const runTabByUrl: Record<string, string> = {
  'tab-all': '/cprm/all|negative=',
  'tab-tab_clients': '/cprm/tab_clients|negative=',
};

export function parseCprmSearch(
  { result: array, run_tabs }: CprmSearchResponse,
  dict: Record<string, string>,
) {
  return array.reduce<ParseSearchReturn>(
    (acc, item) => {
      const tabStartUrl = runTabByUrl[item.run_tab];

      acc.searchResult.push({
        link: `/cprm/${item.link}`,
        path: dict[tabStartUrl],
        text: item.email,
      });

      acc.countByPath[dict[tabStartUrl]] = run_tabs[item.run_tab];

      return acc;
    },
    {
      searchResult: [],
      countByPath: {},
    },
  );
}
