import { NodebackSearchResponse } from '../../services/Types/nodeback.types';

export function getCountsFromNodeBack(counts: NodebackSearchResponse['count']) {
  const { total, ...data } = counts;

  // add '/bo/' for every key
  return Object.fromEntries(
    Object.entries(data).map(([key, value]) => [`/bo/${key}`, value]),
  );
}
