export enum Service {
  Recon = 'RECON',
  RawData = 'RAW_DATA',
  AuthDB = 'AUTHDB',
  SymbolDB = 'SYMBOLDB',
  SymbolDBEditor = 'SYMBOLDB_EDITOR',
  StaticData = 'STATIC_DATA',
  WebBO = 'WEBBO',
  BO = 'BO',
  CAS = 'CAS',
  CAS_API = 'CAS_API',
  NS = 'NS',
  NS_API = 'NS_API',
  SymbolDBUI = 'SYMBOLDB_UI',
  OrderManagementUI = 'ORDER_MANAGEMENT_UI',
  MIFIR = 'MIFIR',
  EMIR = 'EMIR',
  OFFSETS = 'OFFSETS',
  Workflow = 'WORKFLOW',
  WorkflowUI = 'WORKFLOW_UI',
  Reporting = 'REPORTING',
  ReconApi = 'RECON_API',
  Crm = 'CRM',
  CrmApi = 'CRM_API',
  Cprm = 'CPRM',
  CprmApi = 'CPRM_API',
  CrmUIReact = 'CRM_UI_REACT',
  CprmUIReact = 'CPRM_UI_REACT',
  AuditLog = 'AUDIT_LOG',
  Checkpoint = 'CHECKPOINT',
  NodeBack = 'NODE_BACK',
  Branding = 'BRANDING_UI',
  RemusUI = 'REMUS_UI',
  Remus = 'REMUS',
  StressTestUI = 'STRESS_TEST_UI',
  LiquidationEstimate = 'LIQUIDATION_ESTIMATE',
  RiskApi = 'RISK_API',
}

export type MenuConfig = MenuItem[];

export type State = {
  status: Status;
  error: null | Error;
  menu: MenuConfig;
  apis: Record<Service, string> | null;
};

export type Config = {
  modules: { [key: string]: any };
  apis: Record<Service, string> | null;
  vars: Record<string, string>;
  theme: { [key: string]: any };
  menu: MenuConfig;
  features: { [key: string]: boolean };
  auth_flows: AuthFlow[];
  auth_data: {
    clientId: string | null;
  };
  logo: string;
};

export type BrandingConfig = {
  alias: string;
  favicon: string;
  runcfg: Config;
};
export type MenuItem = {
  text: string;
  vpn?: boolean;
  iconSrc?: {
    default: string;
    active: string;
  };
  linkTo?: string;
  children?: MenuItem[];
  isSubmenuHeader?: boolean;
  disabled?: boolean;
  crmId?: string;
  defaultLink?: string;
  isVisible?: boolean;
  isFavourite?: boolean;
};

export enum Status {
  Loading = 'loading',
  Success = 'success',
  Failed = 'failed',
}

type LoadingAction = {
  type: Status.Loading;
  payload: never;
};

type FailedAction = {
  type: Status.Failed;
  payload: Error;
};

type SuccessAction = {
  type: Status.Success;
  payload: { menu: MenuConfig; apis: Record<Service, string> };
};

export type Action = LoadingAction | FailedAction | SuccessAction;

export interface ResponseError extends Error {
  response: {
    config: Record<string, unknown>;
    data: string;
    header: Record<string, unknown>;
    request: Record<string, unknown>;
    status: number;
    statusText: string;
  };
}

export type CrmBookmarkItem = {
  name: string;
  id: number;
  table_name: string;
  order: number;
};

export type TCrmBookmarksData = { data: CrmBookmarkItem[] } | null;

export type TCrmMenuItem = {
  id: string;
  text: string;
  linkTo: string;
  bookmarkTab?: string;
  children?: TCrmMenuItem[];
  isExternal?: boolean;
};

export type TCrmMenu = { data: TCrmMenuItem[] } | null;

export type AuthFlow =
  | { type: 'credentials'; isOn: boolean }
  | {
      isOn: boolean;
      type: 'oauth';
      hint: 'google' | 'appleid';
      binding: boolean;
      iconDark: string;
      iconLight: string;
      text: string;
    };
