import { CancelTokenSource } from 'axios';

import { useApi } from '../../config/useApi';
import { CPRM_SERVICE } from '../auth/const';

import {
  apiService,
  createInstance,
  updateInstanceToken,
} from './Api/Api.service';
import { getAccessToken } from './Cookies.service';

const cprmInstance = createInstance(CPRM_SERVICE);

export const useCprmService = () => {
  const { getCprmApiBaseUrl } = useApi();

  const cprmApi = apiService(
    `${getCprmApiBaseUrl()}/clientsarea/crm/api`,
    cprmInstance,
  );
  const token = getAccessToken();
  updateInstanceToken(cprmInstance, token);

  const requestCprmSearch = async (
    value: string,
    cancelToken: CancelTokenSource,
  ) => {
    return cprmApi.get({
      url: `${getCprmApiBaseUrl()}/rest/`,
      endPoint: `global-search/search/?query=${encodeURIComponent(value)}`,
      cancelToken: cancelToken.token,
    });
  };

  return {
    requestCprmSearch,
  };
};
