import { NodebackSearchResponse } from '../../services/Types/nodeback.types';
import { ParseSearchReturn } from '../types';

export function parseNodeBackSearch(
  nodeBackResponse: NodebackSearchResponse,
  dict: Record<string, string>,
): ParseSearchReturn {
  const URL = {
    accounts: '/bo/accounts',
    clients: '/bo/clients',
    users: '/bo/users',
  };

  const accounts = nodeBackResponse.accounts.map((account) => ({
    link: `${URL.accounts}/${account.id}`,
    path: dict[URL.accounts],
    text: account.id,
  }));

  const clients = nodeBackResponse.clients.map((client) => ({
    link: `${URL.clients}/${client.id}`,
    path: dict[URL.clients],
    text: client.id,
  }));

  const users = nodeBackResponse.users.map((user) => ({
    link: `${URL.users}/${user.id}`,
    path: dict[URL.users],
    text: user.username,
  }));

  return {
    searchResult: [...accounts, ...clients, ...users],
    countByPath: {
      [dict[URL.accounts]]: nodeBackResponse.count.accounts,
      [dict[URL.clients]]: nodeBackResponse.count.clients,
      [dict[URL.users]]: nodeBackResponse.count.users,
    },
  };
}
