import { MenuItem } from '../../menu/types';

/**
 * Creates a dictionary mapping menu item links to their respective paths within the nested menu structure.
 *
 * This function recursively traverses the provided array of menu items, constructing an object where each key
 * is a `linkTo` value from the menu item, and each value is the path to that menu item in the nested structure.
 * The path is represented as a string, with indices indicating the position of the item within the array and nested
 * children.
 *
 * @param {MenuItem[]} array - The array of menu items to traverse.
 * @param {string} [parentPath=''] - The base path used for recursion, default is an empty string.
 * @returns {Record<string, string>} An object mapping `linkTo` values to their respective paths in the menu structure.
 *
 * @example
 * const menu = [
 *   { linkTo: '/bo/accounts', children: [{ linkTo: '/bo/accounts/sub' }] },
 *   { linkTo: '/bo/clients' },
 *   { linkTo: '/bo/audit-log' },
 *   { linkTo: '/bo/commissions' }
 * ];
 *
 * const result = generateLinkPathMap(menu);
 *
 * // Example output:
 * {
 *   '/bo/accounts': '0',
 *   '/bo/accounts/sub': '0.children.0',
 *   '/bo/clients': '1',
 *   '/bo/audit-log': '2',
 *   '/bo/commissions': '3'
 * }
 */
export function generateLinkPathMap(array: MenuItem[], parentPath = '') {
  let result: Record<string, string> = {};

  array.forEach((item, index) => {
    const path = parentPath + index;

    if (item.linkTo) {
      result[item.linkTo] = path;
    }

    if (item.children) {
      result = {
        ...result,
        ...generateLinkPathMap(item.children, `${path}.children.`),
      };
    }
  });

  return result;
}
