import { AxiosResponse, CancelTokenSource } from 'axios';

import { useApi } from '../../config/useApi';
import { CRM_SERVICE } from '../auth/const';

import {
  apiService,
  createInstance,
  updateInstanceToken,
} from './Api/Api.service';
import { getAccessToken } from './Cookies.service';
import { SearchBookmarkData, TCRMBookmarkItem } from './Types/bookmarks.types';
import { CrmSearchResponse } from './Types/crm.types';

const crmInstance = createInstance(CRM_SERVICE);

export const useCrmService = () => {
  const { getCrmApiBaseUrl } = useApi();

  const crmApi = apiService(
    `${getCrmApiBaseUrl()}/clientsarea/crm/api`,
    crmInstance,
  );
  const token = getAccessToken();
  updateInstanceToken(crmInstance, token);

  const getBookmarksListReq = async (id: number, query: string) => {
    const { data }: AxiosResponse<SearchBookmarkData> = await crmApi.get({
      url: `${getCrmApiBaseUrl()}/rest/`,
      endPoint: `global-search/bookmarks/${id}/by_clients/?query=${query}`,
    });

    return { url: `bookmark-${id}`, count: data.count };
  };

  const getBookmarksCrmUi = async () => {
    const { data }: AxiosResponse<TCRMBookmarkItem[]> = await crmApi.get({
      url: `${getCrmApiBaseUrl()}/rest/`,
      endPoint: `bo-like-bookmarks/`,
    });

    return { data };
  };

  const requestCrmSearch = async (
    value: string,
    cancelToken: CancelTokenSource,
  ): Promise<AxiosResponse<CrmSearchResponse>> => {
    return crmApi.get({
      url: `${getCrmApiBaseUrl()}/rest/`,
      endPoint: `global-search/search/?query=${encodeURIComponent(value)}`,
      cancelToken: cancelToken.token,
    });
  };

  return {
    getBookmarksListReq,
    requestCrmSearch,
    getBookmarksCrmUi,
  };
};
