import { MenuItemWithSearch } from '../../menu/types';

/**
 * Filters nodes in a tree-like structure based on the presence of a `search` property.
 * Recursively processes the nodes and their children, retaining nodes that either have
 * a `search` property or have children that match the filter criteria.
 *
 * @param {Object[]} nodes - An array of nodes to be filtered.
 * Each node should be an object that may have the following properties:
 *   - {string} [search] - A string property used for filtering.
 *   - {Object[]} [children] - An array of child nodes (recursively processed).
 *
 * @returns {Object[]} A new array containing only the nodes that have a `search` property
 * or have children that satisfy the filtering criteria.
 */
export function deleteNodesWithoutSearch(nodes: MenuItemWithSearch[]) {
  return nodes.reduce((result, node) => {
    // Create a new node with updated children
    const newNode = { ...node };

    // Recursively filter child nodes
    if (newNode.children) {
      newNode.children = deleteNodesWithoutSearch(newNode.children);
    }

    // Check if the node itself or its children have a `search` property
    if (newNode.search || (newNode.children && newNode.children.length > 0)) {
      result.push(newNode);
    }

    return result;
  }, [] as MenuItemWithSearch[]);
}
