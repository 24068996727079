import { clearedLink } from '../../menu/helpers';
import { CrmSearchResponse } from '../../services/Types/crm.types';
import { ParseSearchReturn } from '../types';

const runTabByUrl: Record<string, string> = {
  'tab-all': '/crm/all|negative=',
  'tab-tab_clients': '/crm/tab_clients|negative=',
  'tab-data-change-requests': '/crm-ui/data-change-requests',
  'tab-deposits': '/crm-ui/deposits',
  'tab-monitoring': '/crm-ui/monitoring',
  'tab-withdrawals': '/crm-ui/withdrawals',
  'tab-contacts': '/crm-ui/contacts',
};

const nonCrmTabs = new Set([
  'tab-withdrawals',
  'tab-data-change-requests',
  'tab-monitoring',
  'tab-deposits',
]);

export function parseCrmSearch(
  { result: array, run_tabs = {} }: CrmSearchResponse,
  dict: Record<string, string>,
) {
  const { searchResult, countByPath } = array.reduce<ParseSearchReturn>(
    (acc, item) => {
      const tabStartUrl = runTabByUrl[item.run_tab];

      let link: string;

      switch (true) {
        case nonCrmTabs.has(item.run_tab):
          link = `${tabStartUrl}/${item.id}`;
          break;
        case item.run_tab === 'tab-contacts':
          link = `/crm/contact-${item.id}`;
          break;
        default:
          link = `/crm/${item.link}`;
          break;
      }

      acc.searchResult.push({
        link,
        path: dict[tabStartUrl],
        text: item.email,
      });

      acc.countByPath[dict[tabStartUrl]] = run_tabs[item.run_tab];

      return acc;
    },
    {
      searchResult: [],
      countByPath: {},
    },
  );

  const crmMenuCount = Object.entries(run_tabs).reduce<Record<string, number>>(
    (acc, [runTab, count]) => {
      if (!count) {
        return acc;
      }

      const tabStartUrl = runTabByUrl[runTab];

      acc[clearedLink(tabStartUrl)] = count;

      return acc;
    },
    {},
  );

  return { searchResult, countByPath, crmMenuCount };
}
