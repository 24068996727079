import { Fragment } from 'react';

import { MenuItemWithSearch } from '../../menu/types';
import {
  SubTitleStyled,
  SearchResultStyled,
  CountStyled,
} from '../GlobalSearch.styled';

const SubItem = ({
  text,
  link,
  onSelectItem,
}: {
  text: string;
  link: string;
  onSelectItem: (link: string) => void;
}) => (
  <SearchResultStyled
    key={`${link}-${text}`}
    onClick={() => onSelectItem(link)}
  >
    {text}
  </SearchResultStyled>
);

export const SubItemList = ({
  menuChildren,
  onSelectItem,
}: {
  menuChildren: MenuItemWithSearch[];
  onSelectItem: (link: string) => void;
}) => (
  <div>
    {menuChildren.map(
      ({ linkTo = '', search, text, children: subChildren, count }) => (
        <Fragment key={text}>
          <SubTitleStyled title={text} onClick={() => onSelectItem(linkTo)}>
            {text}
            <CountStyled>{count}</CountStyled>
          </SubTitleStyled>

          {subChildren ? (
            <SubItemList
              menuChildren={subChildren}
              onSelectItem={onSelectItem}
            />
          ) : (
            <div>
              {search?.map(({ text: subText, link }) => (
                <SubItem
                  key={link}
                  text={subText}
                  link={link}
                  onSelectItem={onSelectItem}
                />
              ))}
            </div>
          )}
        </Fragment>
      ),
    )}
  </div>
);
